import { cva, type VariantProps } from 'class-variance-authority';

export { default as ButtonComponent } from './ButtonComponent.vue';

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-teal-600 text-primary-foreground hover:bg-teal-700',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-gray-300 hover:bg-gray-400',
        ghost:
          'hover:bg-gray-300 font-normal text-normal text-fg-default hover:text-accent-foreground',
        link: 'text-primary hover:bg-gray-400',
        transparent: 'bg-transparent text-gray-900 hover:bg-gray-400',
        tableRowEdit:
          'bg-transparent text-gray-900 hover:bg-gray-400 invisible h-10 w-10 group-hover:visible',
        tableRowDelete:
          'bg-transparent text-gray-900 hover:bg-gray-400 invisible h-10 w-10 group-hover:visible',
      },
      size: {
        default: 'h-9 px-4 py-3',
        xs: 'h-7 rounded px-2',
        sm: 'h-8 rounded px-3',
        lg: 'h-11 rounded px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
