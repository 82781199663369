import { type RouteRecordRaw } from 'vue-router';

const Locations: Array<RouteRecordRaw> = [
  {
    path: '/locations/',
    name: 'Locations',
    component: () => import('../pages/locations/LocationsIndex.vue'),
    props: true,
    meta: {
      title: 'Locations',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/location/create',
    name: 'LocationsCreate',
    component: () => import('../pages/locations/LocationsEdit.vue'),
    props: true,
    meta: {
      title: 'Set up a new location',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/locations/:reference',
    name: 'LocationsDetail',
    component: () => import('../pages/locations/LocationsDetail.vue'),
    props: true,
    meta: {
      title: `Location `,
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/locations/:reference/edit',
    name: 'LocationsEdit',
    component: () => import('../pages/locations/LocationsEdit.vue'),
    props: true,
    meta: {
      title: 'Edit location',
      bodyClass: 'legacy-form',
    },
  },
];

export default Locations;
