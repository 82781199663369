<template>
  <div class="flex min-h-screen">
    <NavSideBar v-if="isAuthenticated" />
    <div class="flex-1 grow">
      <NavBarBase v-if="isAuthenticated" />
      <div id="top-alerts">
        <BaseAlert v-if="showAlertInfo" variant="info" icon="fa-regular fa-rocket">
          <FontAwesomeIcon
            @click="showAlertInfo = false"
            class="pointer float-right h-4 w-4"
            icon="fa-regular fa-times"
          />
          <strong>Heads Up!</strong>
          We're updating our interface in the coming weeks. Have feedback or run into issues?
          <a
            href="https://github.com/ShipitSmarter/support/issues"
            target="_blank"
            class="underline"
          >
            Contact us here
          </a>
        </BaseAlert>
        <BaseAlert v-if="route.meta.testMode" variant="warning">
          <strong>Welcome to test mode!</strong>
          Test shipments are not shipped.
        </BaseAlert>
      </div>
      <div
        class="xl:max-w-screen-xxl mx-auto px-5 pt-3 lg:max-w-screen-xl"
        :class="routerViewClass"
      >
        <RouterView />
      </div>
      <Toaster />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import BaseAlert from '@/components/alert/BaseAlert.vue';
import NavBarBase from '@/components/navbar/NavbarBase.vue';
import { Toaster } from '@/components/ui/sonner';
import { useContextStore } from '@/store';
import { useUserStore } from '@/store/user';
import NavSideBar from '@/views/navigation/NavSideBar.vue';

const router = useRouter();
const contextStore = useContextStore();
const userStore = useUserStore();

const route = useRoute();

const showAlertInfo = useStorage('ShowAlert1', true, sessionStorage);

// Use a computed property to get the class from the route meta
const routerViewClass = computed(() => route.meta.bodyClass || '');

const isAuthenticated = computed(() => userStore.isAuthenticated);

onMounted(async () => {
  await router.isReady();
  if (isAuthenticated.value) {
    await contextStore.loadLocations();
  }
});
</script>
