import { type App } from 'vue';

import { isOk } from '@/generated/clients';
import createUntypedClient from '@/generated/untypedClient';

export interface iAccess extends Record<AccessKey, boolean> {}

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: (key: AccessKey) => Promise<boolean>;
    $auths: (key: AccessKey[]) => Promise<iAccess>;
  }
}

export enum AccessKey {
  CREATE_SHIPMENT,
  VIEW_SHIPMENTS,
  UPDATE_SHIPMENT,
  DELETE_SHIPMENT,
  BOOK_SHIPMENT,

  EDIT_CONFIG,

  VIEW_TOKENS,
  VIEW_TOKEN,
  CREATE_TOKEN,
  DELETE_TOKEN,

  VIEW_INTERNAL,
}

const untypedDecisionsClient = createUntypedClient({
  baseUrl: '/auth/decisions',
});

const access: Record<AccessKey, string> = {
  [AccessKey.CREATE_SHIPMENT]: '/POST/api/shipping/v2/shipments',
  [AccessKey.VIEW_SHIPMENTS]: '/GET/api/shipping/v2/shipments',
  [AccessKey.UPDATE_SHIPMENT]: '/PUT/api/shipping/v2/shipment/:id',
  [AccessKey.DELETE_SHIPMENT]: '/DELETE/api/shipping/v2/:id',
  [AccessKey.BOOK_SHIPMENT]: '/PUT/api/shipping/v2/shipment/:id/order',

  [AccessKey.EDIT_CONFIG]: '/POST/api/configs/v1/',

  [AccessKey.VIEW_TOKENS]: '/GET/api/configs/v1/tokens',
  [AccessKey.VIEW_TOKEN]: '/GET/api/configs/v1/tokens/:id',
  [AccessKey.CREATE_TOKEN]: '/POST/api/configs/v1/tokens',
  [AccessKey.DELETE_TOKEN]: '/DELETE/api/configs/v1/tokens/:hash',

  [AccessKey.VIEW_INTERNAL]: '/GET/api/internal',
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$auth = async (key: AccessKey): Promise<boolean> => {
      const res = await untypedDecisionsClient.GET(access[key]);
      return isOk(res);
    };

    app.config.globalProperties.$auths = async (keys: AccessKey[]): Promise<iAccess> => {
      const hasAccess: iAccess = {
        [AccessKey.CREATE_SHIPMENT]: false,
        [AccessKey.VIEW_SHIPMENTS]: false,
        [AccessKey.UPDATE_SHIPMENT]: false,
        [AccessKey.DELETE_SHIPMENT]: false,
        [AccessKey.BOOK_SHIPMENT]: false,

        [AccessKey.EDIT_CONFIG]: false,

        [AccessKey.VIEW_TOKENS]: false,
        [AccessKey.VIEW_TOKEN]: false,
        [AccessKey.CREATE_TOKEN]: false,
        [AccessKey.DELETE_TOKEN]: false,

        [AccessKey.VIEW_INTERNAL]: false,
      };

      const permissions = Promise.all(
        keys.map(async (key) => {
          try {
            const res = await untypedDecisionsClient.GET(access[key]);
            hasAccess[key] = isOk(res);
          } catch (error) {
            return;
          }
        })
      );

      return permissions.then(() => {
        return hasAccess;
      });
    };
  },
};
