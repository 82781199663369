import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { type Component } from 'vue';

export const ViyaGlobalComponents: Record<string, Component> = {
  FontAwesomeIcon: FontAwesomeIcon,
};

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FontAwesomeIcon: typeof FontAwesomeIcon;
  }
}
