import { type _GettersTree, acceptHMRUpdate, defineStore } from 'pinia';

import { type components as configsComponents } from '@/generated/configs';

// export types for other components to use in the future we can refactor it here only once;
export type IntegrationPath = configsComponents['schemas']['CarrierProfileIntegrationPath'];
export type IntegrationType = configsComponents['schemas']['IntegrationType'];
export type InstructionsShemas = configsComponents['schemas']['InstructionSchemas'];
export type Integrations = Record<IntegrationType, IntegrationPath>;
export type InstructionValues = Record<string, InstructionsShemas>;
interface Profile {
  label: string;
  integrationPaths: object;
  integrationSetReference: string;
  reference: string;
}

interface Carrier {
  description: string;
  logo: object;
  reference: string;
}

interface OnboardingStep {
  title: string;
  value: string;
  completed: boolean;
}

interface OnboardingDetails {
  required: boolean;
  completed?: boolean;
  steps?: Partial<OnboardingStep[]>;
}

interface LocationOnboarding {
  onboarding: OnboardingDetails;
}
interface ReferenceLocations {
  [location: string]: LocationOnboarding;
}

interface OnboardingPayload {
  location: string;
  reference: string;
  onboarding: OnboardingDetails;
}
interface Onboarding {
  [reference: string]: ReferenceLocations;
}
interface State {
  profile: Profile;
  carrier: Carrier;
  integrations: Integrations;
  onboarding: Onboarding;
  instructionValues: InstructionValues;
  shownLiveValues: Array<IntegrationType>;
}

interface Actions {
  setProfile: (data: Profile) => void;
  setCarrier: (data: Carrier) => void;
  setIntegrations: (data: Partial<Integrations>) => void;
  setOnboarding: ({ location, reference, onboarding }: OnboardingPayload) => void;
  setInstructionValues: (data: InstructionValues) => void;
  resetToggleLiveValues: () => void;
  setToggleLiveValues: (data: IntegrationType) => void;
}
interface Getters extends _GettersTree<State> {
  getCarrier: (state: State) => Carrier;
  getProfile: (state: State) => Profile;
  getIntegrations: (state: State) => Integrations;
  getOnBoarding: (state: State) => Onboarding;
  getShownLiveValues: (state: State) => IntegrationType[];
  getCurrentOnboarding: (
    state: State
  ) => ({
    location,
    reference,
  }: {
    location: string;
    reference: string;
  }) => Maybe<OnboardingDetails>;
}

export const useCarrierProfileStore = defineStore<string, State, Getters, Actions>(
  'carrierProfile',
  {
    state: () => {
      return {
        profile: {} as Profile,
        carrier: {} as Carrier,
        integrations: {} as Integrations,
        onboarding: {} as Onboarding,
        instructionValues: {} as InstructionValues,
        shownLiveValues: [] as IntegrationType[],
      };
    },
    getters: {
      getCarrier: (state) => state.carrier,
      getProfile: (state) => state.profile,
      getIntegrations: (state) => state.integrations,
      getOnBoarding: (state) => state.onboarding,
      getShownLiveValues: (state) => state.shownLiveValues,
      getCurrentOnboarding:
        (state) =>
        ({ location, reference }) => {
          return state?.onboarding?.[reference]?.[location]?.onboarding;
        },
    },
    actions: {
      setProfile(data) {
        this.profile = data;
      },
      setCarrier(data) {
        this.carrier = data;
      },
      setIntegrations(data) {
        this.integrations = { ...this.integrations, ...data };
      },
      setInstructionValues(data) {
        this.instructionValues = data;
      },
      resetToggleLiveValues() {
        this.shownLiveValues = [];
      },
      setToggleLiveValues(integrationItem) {
        this.shownLiveValues = this.shownLiveValues.includes(integrationItem)
          ? [...this.shownLiveValues.filter((item) => item !== integrationItem)]
          : [...this.shownLiveValues, integrationItem];
      },
      setOnboarding({ location, reference, onboarding }): void {
        // safeguard against when passing undefined
        if (!location || !reference) {
          return;
        }
        const updated = {
          ...this.onboarding,
          [reference]: {
            ...(this.onboarding?.[reference] as ReferenceLocations),
            [location]: {
              ...(this.onboarding?.[reference] as ReferenceLocations)?.[location],
              onboarding: {
                ...onboarding,
              },
            },
          },
        };
        this.onboarding = { ...updated };
      },
    },
  }
);

// hmr with vue-cli
// https://pinia.vuejs.org/cookbook/hot-module-replacement.html
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCarrierProfileStore, import.meta.hot));
}
