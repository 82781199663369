<template>
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger class="justify-between sm:w-60 md:w-72 lg:w-96">
          <FontAwesomeIcon icon="fa-regular fa-compass" class="mr-2 h-5 w-5 text-teal-600" />
          <span class="text-base">Quicklinks</span>
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/shipments'"
                  :class="hasAccess(AccessKey.VIEW_SHIPMENTS)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="font-medium leading-none">
                    <FontAwesomeIcon
                      icon="fa-solid fa-warehouse-full"
                      class="mr-2 h-4 w-4 text-teal-600"
                    />
                    Shipments
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Overview of all shipments
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/shipments/create'"
                  :class="hasAccess(AccessKey.CREATE_SHIPMENT)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="font-medium leading-none">
                    <FontAwesomeIcon
                      icon="fa-regular fa-rocket"
                      class="mr-2 h-4 w-4 text-teal-600"
                    />
                    Create Shipment
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Create a new shipment
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/address'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="font-medium leading-none">
                    <FontAwesomeIcon
                      icon="fa-regular fa-address-book"
                      class="mr-2 h-4 w-4 text-teal-600"
                    />
                    Address Book
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Manage your shipment addresses, for sender, receiver, and more.
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li />
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/test/shipments/create'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="font-medium leading-none">
                    <FontAwesomeIcon
                      icon="fa-regular fa-flask"
                      class="mr-2 h-4 w-4 text-yellow-600"
                    />
                    <span class="text-yellow-800">Create test shipment</span>
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    To test playground settings
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/test/shipments'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                >
                  <div class="font-medium leading-none">
                    <FontAwesomeIcon
                      icon="fa-regular fa-flask"
                      class="mr-2 h-4 w-4 text-yellow-600"
                    />
                    <span class="text-yellow-800">Test Shipments</span>
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted-foreground">
                    Overview of all test shipments
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
</template>

<script setup lang="ts">
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { AccessKey } from '@/plugins/authorization';
import { useUserStore } from '@/store/user'; // Assuming the user store is in a separate file

// Define the hasAccess method
const hasAccess = (key: AccessKey): string => {
  const accessStore = useUserStore(); // Call the hook with parentheses

  // Check access and return appropriate string value
  return accessStore.getAccess[key] === false ? 'no-access' : '';
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}
</style>
