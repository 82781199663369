import { type RouteRecordRaw } from 'vue-router';

const Tracking: Array<RouteRecordRaw> = [
  {
    path: '/consignments',
    name: 'trackingOverview',
    component: () => import('../pages/tracking/3m-tracking-overview.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/consignments/tracking',
    name: 'trackingSignedUrl',
    component: () => import('../pages/tracking/3m-tracking.vue'),
    props: (route) => ({ signedUrl: route.query.signedUrl as string }),
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
      allowAnonymous: true,
    },
  },
  {
    path: '/consignments/tracking/:id',
    name: 'tracking',
    component: () => import('../pages/tracking/3m-tracking.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/consignments/tracking/config',
    name: 'trackingConfiguration',
    component: () => import('../pages/tracking/3m-tracking-config.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
    },
  },
];

export default Tracking;
